import { Button, Container } from '@energiebespaarders/symbols';
import { Large } from '@energiebespaarders/symbols/helpers';
import { Home } from '@energiebespaarders/symbols/icons/solid';
import Head from 'next/head';
import styled from 'styled-components';
import BackgroundWrapper from '~/components/BackgroundWrapper';

const StyledHeading = styled.h1`
  margin: 0 auto;
  max-width: 400px;
  font-size: 100px;
`;

export const NotFound = () => (
  <>
    <Head>
      <title key="title">404 - Pagina niet gevonden</title>
      <meta name="description" content="Pagina niet gevonden" key="meta-description" />
    </Head>
    <BackgroundWrapper py={10}>
      <Container size="md" textAlign="center" px={2}>
        <StyledHeading className="green medium glitch" data-text="404">
          404
        </StyledHeading>
        <Large>
          <p>De pagina die je zoekt bestaat helaas niet (meer)</p>
        </Large>
        <div>
          <Button
            to="/"
            inverse
            title="Terug naar de homepage"
            fontSize={6}
            label="Ga terug naar de homepage"
            iconStart={Home}
            mt={5}
          />
        </div>
      </Container>
    </BackgroundWrapper>
  </>
);

export default NotFound;
