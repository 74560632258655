import { Box } from '@energiebespaarders/symbols';
import { breakpoints } from '@energiebespaarders/symbols/styles';
import { tint } from '@energiebespaarders/symbols/styles/mixins';
import styled from 'styled-components';
import useRouteProps from '~/hooks/useRouteProps';
import newTheme from '~/styles/theme';
import type { SpacingProps } from '@energiebespaarders/symbols/lib/hoc/withSpacing';

interface StyledBackgroundWrapperProps {
  $bgColor?: string;
  $fillViewportHeight?: boolean;
  $topOffset: number;
  $bottomOffset?: number;
}

const StyledBackgroundWrapper = styled(Box)<StyledBackgroundWrapperProps>`
  background: ${x =>
    x.$bgColor ??
    `linear-gradient(135deg, ${tint(0.5, x.theme.colors.greenLighter)}, ${tint(
      0.5,
      x.theme.colors.yellowLighter,
    )})`};
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  min-height: ${x =>
    x.$fillViewportHeight ? '100vh' : `calc(100vh - ${x.$topOffset}px - ${x.$bottomOffset}px)`};

  /* For small height screens, e.g. small phone with virtual keyboard open, push footer further back down so it doesn't cover page content */
  @media (max-height: ${breakpoints.sm}) {
    min-height: 100vh;
  }
`;

interface BackgroundWrapperProps extends SpacingProps {
  bgColor?: string;
  fillViewportHeight?: boolean;
  topOffset?: number;
  bottomOffset?: number;
}

const BackgroundWrapper: React.FC<BackgroundWrapperProps> = ({
  bgColor,
  fillViewportHeight,
  children,
  ...props
}) => {
  const routeProps = useRouteProps();
  const navHeight = parseInt(newTheme.fixedSizes.navHeight);
  const topOffset = props.topOffset ?? (routeProps?.hideNav ? 0 : navHeight);
  const bottomOffset =
    props.bottomOffset ?? (routeProps?.hideFooter ? 0 : parseInt(newTheme.fixedSizes.footerHeight));

  return (
    <StyledBackgroundWrapper
      width={1}
      $fillViewportHeight={fillViewportHeight}
      $bgColor={bgColor}
      $topOffset={topOffset}
      $bottomOffset={bottomOffset}
      {...props}
    >
      {children}
    </StyledBackgroundWrapper>
  );
};

export default BackgroundWrapper;
